import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SimpleReactLightbox from 'simple-react-lightbox'
// import { GlobalStyle } from '../../style/GlobalStyle';
// import { SEO } from '../SEO/SEO';
import 'react-image-lightbox/style.css';
import '../../assets/scss/style.scss'
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import WhatsApp from '../common/whatsapp/WhatsApp';
// import Messenger from '../common/messenger/Messenger';


declare global {
  interface Window {
      gtag:any;
  }
}

type LayoutProps = {
  pagePath?: string
  getNavHeight?: (h: number) => void
}

const Layout: React.FC<LayoutProps> = props => {

  const data = useStaticQuery(graphql`
    query {
        allContentfulProyecto {
          edges {
            node {
              direccion
              centralTelefonica
              telefonosMoviles
              emails
              sobreUbicacion
            }
          }
        }
      }
    `);

    const proyectoNode = data.allContentfulProyecto.edges[0].node;
    const sobreUbicacion = proyectoNode.sobreUbicacion as string;
    const telefonos = proyectoNode.centralTelefonica as string[] || [];
    const moviles = proyectoNode.telefonosMoviles as string[] || [];
    const emails = proyectoNode.emails as string[] || [];
    const direccion = proyectoNode.direccion as string;

  return (
    <SimpleReactLightbox>
      {/* <GlobalStyle /> */}
      {/* <SEO location={location} /> */}
      <Header telefonos={telefonos} pagePath={props.pagePath} getNavHeight={props.getNavHeight}/>
      {props.children}
      <Footer sobreUbicacion={sobreUbicacion} telefonos={telefonos} moviles={moviles} emails={emails} direccion={direccion}/>
      {/* <WhatsApp /> */}
    </SimpleReactLightbox>
  )
};

export default Layout

import React, {Component} from 'react';

class Copyright extends Component {
    render (){
        const today = new Date();
        const year = today.getFullYear();
        return (
            <div className="copyright">
                <p>Copyright © {year} {this.props.title}. Reservados todos los derechos.</p>

            </div>
        )
    }
}

export default Copyright
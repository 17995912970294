import React, { Component, useState, useEffect, useRef, Fragment } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
// import Form from 'react-validation/build/form';
// import Input from 'react-validation/build/input';
// import CheckButton from 'react-validation/build/button';
// import Textarea from 'react-validation/build/textarea'

import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { Form } from 'react-bootstrap';

// import { setCotizar, sendQuotation, sendMessage } from '../../../store/actions/general';
// import './contact-form.scss';

const ContactForm = props => {
    // const appState = useSelector(state => state);

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [content, setContent] = useState('');
    const [dni, setDni] = useState('');
    const [sending, setSending] = useState(false)
    const [resetForm, setResetForm] = useState(false);

    const checkBtn = useRef(null);
    const form = useRef(null);

    // const dispatch = useDispatch();

    const validRequired = (value) => {
        if (isEmpty(value)) {
            return <small className="form-text text-danger">Este campo es requerido</small>;
        }
    }

    const validEmail = (value) => {
        if (!isEmail(value)) {
            return <small className="form-text text-danger">Formato de correo inválido</small>;
        }
    }

    const validPhone = (value) => {
        if (!isMobilePhone(value)) {
            return <small className="form-text text-danger">Formato de número de teléfono no válido</small>;
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setSending(true);
        // form.current.validateAll();
        let formData = new FormData();
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        const url = `https://getform.io/f/${process.env.GATSBY_MESSAGE_URL}`;
        if (props.cotizarForm) {
            typeof window !== "undefined" && window.gtag && window.gtag("event", "click", {
                category: 'Contacto',
                action: 'mandando cotizacion',
                label: props.origen
            });
            // sendQuotation({ dni, name, email, phone, content, type: props.dptoSelected });
            formData.append('proyecto', 'pagina mahpsa');
            formData.append('tipo', 'cotizacion');
            formData.append('dni', dni);
            formData.append('nombre', name);
            formData.append('correo', email);
            formData.append('dpto', props.dptoSelected);
            formData.append('telefono', phone);
            formData.append('mensaje', content);
            try {
                await axios.post(url, formData, config)
                setSending(false);
                props.closeModal();
                // alert('Mensaje mandado!' + '\n name: ' + name + '\n email: ' + email + '\n phone:' + phone + '\n content:' + content + '');
            } catch (error) {
                console.log('Eror sending message:');
                console.log(error)
            }
            // dispatch(setCotizar(!appState.cotizar));
        } else {
            typeof window !== "undefined" && window.gtag && window.gtag("event", "click", {
                category: 'Contacto',
                action: 'mandando mensaje',
                label: props.origen
            });
            // sendMessage({name, email, phone, content});
            formData.append('proyecto', 'rosaura');
            formData.append('tipo', 'mensaje');
            formData.append('nombre', name);
            formData.append('correo', email);
            formData.append('telefono', phone);
            formData.append('mensaje', content);
            try {
                await axios.post(url, formData, config)
                setSending(false);
                props.closeModal();
                // alert('Mensaje mandado!' + '\n name: ' + name + '\n email: ' + email + '\n phone:' + phone + '\n content:' + content + '');
            } catch (error) {
                console.log('Eror sending message:');
                console.log(error)
            }
        }



    }


    return (
        <Form className="form-group-v1" onSubmit={e => onSubmit(e)} ref={form} >
            {props.cotizarForm &&
                (<div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group mb-3">
                            <input name="dni" onChange={(e) => setDni(e.target.value)} value={dni} type="text" placeholder="Tu dni *" className="form-control" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group">

                        </div>
                    </div>
                </div>)
            }

            <div className="form-group mb-3">
                <input name="name" onChange={(e) => setName(e.target.value)} value={name} type="text" placeholder="Tu Nombre*" className="border border-white text-white form-control" />
            </div>

            <div className="form-group mb-3">
                <input name="email" onChange={(e) => setEmail(e.target.value)} value={email} type="email" placeholder="Tu e-mail *" className="border border-white text-white form-control" />
            </div>


            <div className="form-group mb-3">
                <input name="phone" onChange={(e) => setPhone(e.target.value)} value={phone} type="text" placeholder="Número de Teléfono *" className="border border-white text-white form-control" />
            </div>

            <div className="form-group mb-3">
                <textarea className="border border-white text-white form-control" onChange={(e) => setContent(e.target.value)} value={content} placeholder="Un Mensaje (Opcional)" rows={3}/>
            </div>
            <div className="mt-4 d-flex justify-content-center align-items-center">
                <button type="submit" className="btn btn-primary btn-block">Enviar</button>
                {sending &&
                    <Fragment>
                        <div className="spinner-border text-primary" role="status" style={{ marginLeft: '20px' }}>
                            <span className="sr-only">Enviando...</span>
                        </div>
                        <div className="ml-2 d-inline-block" style={{ marginLeft: '20px' }}>Enviando...</div>
                    </Fragment>
                }
            </div>
            {/* <CheckButton style={{ display: 'none' }} ref={checkBtn} /> */}
        </Form>
    )

}

export default ContactForm
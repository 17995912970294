import React, { useEffect, useRef, useState } from "react"
// import {Link, NavLink} from 'react-router-dom';
import { Link } from "gatsby"

// Import custom components
// import LogoImage from "./Logo";
// import {connect} from "react-redux";

import {
  Container,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap"
// import ContactForm from "../contact-form/contact-form";
// import { usePushMenu } from 'react-push-menu';
import { StaticImage } from "gatsby-plugin-image"
import ContactForm from "../common/contact-form/contact-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"

type HeaderProps = {
  telefonos: string[]
  pagePath?: string
  getNavHeight?: (h: number) => void
}

const Header: React.FC<HeaderProps> = props => {
  // const [scrolling, setScrolling] = useState(false)
  // let listener = null
  // useEffect(() => {
  //     listener = document.addEventListener("scroll", e => {
  //         var scrolled = document.scrollingElement.scrollTop
  //         if (scrolled >= 120) {
  //             if (!scrolling) {
  //                 setScrolling(true)
  //             }
  //         } else {
  //             if (scrolling) {
  //                 setScrolling(false)
  //             }
  //         }
  //     })
  //     return () => {
  //         document.removeEventListener("scroll", listener)
  //     }
  // }, [scrolling])
  const navRef = useRef<any>(null)
  const [navToggled, setNavToggled] = useState(false)
  const [isMobileScreen, setIsMobileScreen] = useState(false)
  const navToggleHandler = (value: boolean) => {
    console.log("TOGGLE VALUE: " + value)
    setNavToggled(value)
  }

  const isIndexPage = props.pagePath === "/" && !isMobileScreen
  let linkColorClass = isIndexPage
    ? "mainmenu-container-link-light"
    : "mainmenu-container-link-dark"

  useEffect(() => {
    console.log("The window inner width is: " + window.innerWidth)
    const isBrowser = () => typeof window !== "undefined"
    if (isBrowser) {
      if (window.innerWidth <= 576) {
        console.log("Setting the mobile screen to true..")
        setIsMobileScreen(true)
      }
    }
    console.log("IS INDEX PAGE: " + isIndexPage)
    if (isIndexPage) {
      console.log("The index page observer...")
      const observer = new ResizeObserver(entries => {
        //   setwidth(entries[0].contentRect.width)

        if (navRef && navRef.current) {
          console.log(
            "UPDATING THE HEIGHT OF THE NAV BAR IS: " +
              navRef.current.offsetHeight
          )
          console.log(entries)
          props.getNavHeight(entries[0].borderBoxSize[0].blockSize)
        }
      })
      observer.observe(navRef.current)
      return () => navRef.current && observer.unobserve(navRef.current)
    }
  }, [])

  console.log("Rendering with index page: " + isIndexPage)
  return (
    <Navbar
      className={isIndexPage && "my-0 py-0"}
      collapseOnSelect
      expand="lg"
      style={isIndexPage ? { background: "transparent", zIndex: "1000" } : {}}
      onToggle={navToggleHandler}
      variant={isIndexPage ? "dark" : "light"}
    >
      <Container
        className={isIndexPage && "mx-0 px-0"}
        style={isIndexPage ? { zIndex: "100000", maxWidth: "100%" } : {}}
      >
        {props.pagePath === "/" && !isMobileScreen ? (
          <div ref={navRef} className="main-header-landing-logo">
            <StaticImage
              src="../../assets/images/mahpsa.png"
              alt="logo mahpsa"
              width={150}
              placeholder={"tracedSVG"}
            />
          </div>
        ) : (
          <Link
            to={"/"}
            className="navbar-brand"
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <span className="screen-reader-text">
              Mahpsa Corporacion inmobiliaria
            </span>
            <StaticImage
              src="../../assets/images/mahpsa.png"
              alt="logo rosaura"
              width={150}
              placeholder={"tracedSVG"}
            />
          </Link>
        )}
        {!navToggled && (
          <div
            className="d-flex d-lg-none justify-content-end me-5"
            style={{ flex: 1 }}
          >
            <FontAwesomeIcon
              icon={faPhoneAlt}
              size="lg"
              style={isIndexPage ? { color: "#f6f6f6" } : {color: "rgb(0, 110, 169)" }}
            />
            <h6
              className={`${
                !isIndexPage && "text-primary"
              } ms-1 d-inline-block p-0 m-0`}
              style={isIndexPage ? { color: "#f6f6f6" } : {}}
            >
              (01)273 0409
            </h6>
          </div>
        )}
        {!navToggled && <Navbar.Toggle aria-controls="responsive-navbar-nav" />}
        {!navToggled && (
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className={`align-self-start flex-column align-items-end ${isIndexPage && 'pe-5 pt-3'}`}
            // style={{ marginRight: "50px" }}
          >
            <div
              className="d-none d-md-flex mb-2 mt-2"
              style={{ color: "#f6f6f6" }}
            >
              <FontAwesomeIcon
                icon={faPhoneAlt}
                size="lg"
                style={isIndexPage ? { color: "#f6f6f6" } : {color: "rgb(0, 110, 169)" }}
              />
              <h6
                className={`${
                  !isIndexPage && "text-primary"
                } ms-1 d-inline-block p-0 m-0`}
                style={isIndexPage ? { color: "#f6f6f6" } : {}}
              >
                (01)273 0409
              </h6>
            </div>
            <Nav>
              <div
                className="d-flex justify-content-end mainmenu-container"
                style={{ flex: 1 }}
              >
                <ul className="top-menu menu flex-column flex-lg-row">
                  <li className={`menu-item`}>
                    <Link
                      className={linkColorClass}
                      to="/"
                      activeClassName="selected"
                    >
                      Home
                    </Link>
                  </li>
                  <li className={`menu-item`}>
                    <Link
                      className={linkColorClass}
                      to="/nosotros"
                      activeClassName="selected"
                    >
                      Nosotros
                    </Link>
                  </li>
                  <li className={`menu-item`}>
                    <Link
                      className={linkColorClass}
                      to="/contactanos"
                      activeClassName="selected"
                    >
                      Contáctanos
                    </Link>
                  </li>
                  <li className={`menu-item`} style={{marginRight: 0}}>
                    <Link
                      className={linkColorClass}
                      to="/servicios"
                      activeClassName="selected"
                      style={{marginRight: 0}}
                    >
                      Servicios
                    </Link>
                  </li>
                </ul>
              </div>
            </Nav>
          </Navbar.Collapse>
        )}
        {navToggled && (
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            style={{ zIndex: 1044 }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav>
                <div className="mainmenu-container">
                  <ul className="top-menu menu flex-column align-items-start">
                    <li className={`menu-item`}>
                      <Link
                        className={"mainmenu-container-link-dark"}
                        to="/"
                        activeClassName="selected"
                      >
                        Home
                      </Link>
                    </li>
                    <li className={`menu-item`}>
                      <Link
                        className={"mainmenu-container-link-dark"}
                        to="/nosotros"
                        activeClassName="selected"
                      >
                        Nosotros
                      </Link>
                    </li>
                    <li className={`menu-item`}>
                      <Link
                        className={"mainmenu-container-link-dark"}
                        to="/contactanos"
                        activeClassName="selected"
                      >
                        Contactanos
                      </Link>
                    </li>
                    <li className={`menu-item`}>
                      <Link
                        className={"mainmenu-container-link-dark"}
                        to="/servicios"
                        activeClassName="selected"
                      >
                        Servicios
                      </Link>
                    </li>
                  </ul>
                </div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        )}
      </Container>
      {isIndexPage && (
        <div className="main-left-panel">
          <div
            style={{ height: "70px", width: "1px", background: "#ececec" }}
          ></div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            className="pb-5"
          >
            <a
              href="#"
              onClick={() =>
                window.open("https://www.facebook.com/mahpsasac", "_blank")
              }
              className={"main-left-panel-social-link mt-2"}
            >
              <span className="screen-reader-text">Facebook</span>
              <FontAwesomeIcon
                icon={faFacebookF}
                size="lg"
                className="main-left-panel-social-icon"
              />
            </a>
            <a
              href="#"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UC74CIWfyOdnzKoOiSJiHxwA",
                  "_blank"
                )
              }
              className={"main-left-panel-social-link mt-2"}
            >
              <span className="screen-reader-text">Youtube</span>
              <FontAwesomeIcon
                icon={faYoutube}
                size="lg"
                className="main-left-panel-social-icon"
              />
            </a>
            <a
              href="#"
              onClick={() =>
                window.open(
                  "https://www.instagram.com/inmobiliariamahpsa/",
                  "_blank"
                )
              }
              className={"main-left-panel-social-link mt-2"}
            >
              <span className="screen-reader-text">Instagram</span>
              <FontAwesomeIcon
                icon={faInstagram}
                size="lg"
                className="main-left-panel-social-icon"
              />
            </a>
            <a
              href="#"
              onClick={() =>
                window.open(
                  "https://www.instagram.com/inmobiliariamahpsa/",
                  "_blank"
                )
              }
              className={"main-left-panel-social-link mt-2"}
            >
              <span className="screen-reader-text">Instagram</span>
              <FontAwesomeIcon
                icon={faWhatsapp}
                size="lg"
                className="main-left-panel-social-icon"
              />
            </a>
          </div>
        </div>
      )}
    </Navbar>
  )
}

export default Header

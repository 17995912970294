import {
  faFacebookSquare,
  faInstagramSquare,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ContentfulRichTextGatsbyReference } from "gatsby-source-contentful/rich-text"
import React, { Component, Fragment } from "react"
import ContactForm from "../common/contact-form/contact-form"
import Copyright from "../common/copyright/copyright"
import Messenger from "../common/messenger/Messenger"
import {
  faPhoneAlt,
  faMapMarker,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"
// import Copyright from "../copyright/copyright";
// import ContactForm from "../contact-form/contact-form";

type FooterProps = {
  sobreUbicacion: string
  telefonos: string[]
  moviles: string[]
  emails: string[]
  direccion: string
}

const FooterOne: React.FC<FooterProps> = ({
  sobreUbicacion,
  telefonos,
  moviles,
  emails,
  direccion,
}) => {
  return (
    <footer className="site-footer">
      <div className="section-2">
        <div className="container">
          <div className="d-block d-lg-flex justify-content-between">
            <div className="col-lg-6 col-md-12">
              <div className="icon-list footer-address">
                <h4 className="mb-4 text-uppercase">Contáctanos</h4>
                <ul>
                  <li className="mb-4">
                    <span className="icon-list-icon me-3">
                      <FontAwesomeIcon
                        icon={faMapMarker}
                        size="lg"
                        className="text-white"
                      />
                    </span>
                    <span className="icon-list-text">
                      Av. Tomas Marsano 2875 Int. 607 Surco, Lima
                    </span>
                  </li>
                  <li className="mb-4">
                    <span className="icon-list-icon me-3">
                      <FontAwesomeIcon
                        icon={faPhoneAlt}
                        size="lg"
                        className="text-white"
                      />
                    </span>
                    <span className="icon-list-text">273 0398 / 273 0409</span>
                  </li>
                  <li className="mb-3">
                    <span className="icon-list-icon me-3">
                      {" "}
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        size="lg"
                        className="text-white"
                      />
                    </span>
                    <span className="icon-list-text font-weight-bold">
                      informes@mahpsa.com.pe
                    </span>
                  </li>
                  <li className="mb-3">
                    <div className="social mt-4 mb-3">
                      <a
                        href="#"
                        onClick={() =>
                          window.open(
                            "https://www.facebook.com/mahpsasac",
                            "_blank"
                          )
                        }
                        className={"mr-3"}
                      >
                        <span className="screen-reader-text">Facebook</span>
                        <FontAwesomeIcon
                          icon={faFacebookSquare}
                          size="2x"
                          className="text-white"
                          style={{ height: "21px" }}
                        />
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          window.open(
                            "https://www.youtube.com/channel/UC74CIWfyOdnzKoOiSJiHxwA",
                            "_blank"
                          )
                        }
                        className={"mr-3"}
                      >
                        <span className="screen-reader-text">Youtube</span>
                        <FontAwesomeIcon
                          icon={faYoutubeSquare}
                          size="2x"
                          className="text-white"
                          style={{ height: "21px" }}
                        />
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          window.open(
                            "https://www.instagram.com/inmobiliariamahpsa/",
                            "_blank"
                          )
                        }
                        className={"mr-3"}
                      >
                        <span className="screen-reader-text">Instagram</span>
                        <FontAwesomeIcon
                          icon={faInstagramSquare}
                          size="2x"
                          className="text-white"
                          style={{ height: "21px" }}
                        />
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className={"container"}>
                      <div className={"row"}>
                        <p style={{ color: "white" }}>
                          <i>
                            **Todas las imagenes en la pagina son referenciales
                          </i>
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <ContactForm origen={"footer"} />
            </div>
          </div>
        </div>
      </div>
      {/*Section #2*/}
      {/*Section 3*/}
      <div className="section-3">
        <div className="container text-center">
          <div className="row">
            <div className="col-sm-12">
              <Copyright title="Mahpsa" />
            </div>
          </div>
        </div>
        {/* <Messenger/> */}
      </div>
    </footer>
  )
}

export default FooterOne
